@charset 'utf-8';


// Custom Paths
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Webfont path
$webfont-path: '../fonts/';


// Custom Variables
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Typography
//$open-sans:                 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, sans-serif;
$manrope:                   'Manrope', 'Helvetica Neue', Helvetica, Roboto, sans-serif;
$bold:                      600;

// Units & Spacing
$full-space-sm:             2.5rem;
$half-space-sm:             ($full-space-sm / 2);
$full-space-md:             3rem;
$half-space-md:             ($full-space-md / 2);
$full-space-lg:             4rem;
$half-space-lg:             ($full-space-lg / 2);
$header-height-sm:          60px;
$header-height-md:          74px;
$header-height-lg:          74px;
$body-max-width:            2560px;
$chip-width:                50px;


// Foundation imports/includes
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
// @include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
// @include foundation-button-group;
// @include foundation-close-button;
// @include foundation-label;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-switch;
// @include foundation-table;
// Basic components
// @include foundation-badge;
// @include foundation-breadcrumbs;
@include foundation-callout;
// @include foundation-card;
@include foundation-dropdown;
// @include foundation-pagination;
// @include foundation-tooltip;

// Containers
// @include foundation-accordion;
// @include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
// @include foundation-tabs;
// @include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
// @include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
// @include foundation-sticky;
// @include foundation-title-bar;
// @include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;


// Custom SCSS imports/includes
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

@import 'global/typography';
@import 'global/layout';
@import 'global/navigation';
@import 'global/images';
@import 'global/buttons';
@import 'global/modules';
@import 'global/helpers';
@import 'global/icons';
@import 'global/print';