// Skip To
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Skip to link
.skip-to, 
.skip-to:visited {
    position: fixed !important;
    padding: $button-padding !important;
    top: 0 !important;
    left: auto !important;
    background-color: $primary-color !important;
    border-radius: 0 !important;
    color: $white !important;
    z-index: 9999 !important;

    // Focus state
	&:focus {
		transition: all 0s ease 0s;
		display: inline-block;
	}
}



// Anchor Offset
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------
 
// Offsets anchors (for sticky header)
a.anchor {
    display: block;
    position: relative;
    top: -60px;
    visibility: hidden;
    border: 0;
}



// Header
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Header Div (fixed)
.header {
    position: fixed;
    top: 0;
    left: auto;
    z-index: 999;
    width: 100%;
    max-width: $body-max-width;
}

// Header Top Div
.header__top {
    background-color: $white;
    color: $black;
    -webkit-box-shadow: $shadow;
    -moz-box-shadow:    $shadow;
    box-shadow:         $shadow;
    height: $header-height-sm;
    transition: all 0.3s ease;

    // "medium" size only
    @include breakpoint(medium only) {
        height: $header-height-md;
    }

    // "large" size +
    @include breakpoint(large) {
        height: $header-height-lg;
    }
}


// Header Logo
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Header logo
.header__logo {
    @extend %link-reset;
    position: absolute;
    top: 17px;
    left: 2rem;
    z-index: 999;
    display: block;
    width: auto;
    height: $header-height-sm - 26px;
    transition: all 0.3s ease;

    // 480px - medium
    @media screen and (min-width: 480px) and (max-width:767px) {
        left: $full-space-sm;
    }

    // "medium" size +
    @include breakpoint(medium) {
        top: 21px;
        left: $full-space-md;
        height: $header-height-md - 34px;
    }

    // "large" size +
    @include breakpoint(large) {
        top: 21px;
        left: $full-space-lg;
        height: $header-height-lg - 34px;
    }

    // img
    img {
        width: auto;
        height: 100%;
    }
}



// Main Nav
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Header main <nav>
.header__menu__main {

    // Top level menu <ul>
    > .menu {
        opacity: 0; // covers js issue
    }

    // Menu <ul> (Desktop and Mobile)
    .menu {
        list-style: none;
        padding: 0;
        margin: 0;
        font-weight: $bold;

        // All <li>'s
        li {

            // Nested <ul>'s
            ul, .nested {
                text-transform: none;

                // "large" size +
                @include breakpoint(large) {
                    font-size: 1rem;
                    background-color: $primary-color;
                }
            }

            // All <a>'s
            a {
                color: $dark-gray;
                border-bottom: 0;

                // Hover state
                &:hover, &:active, &:focus {
                    background-color: $hover-darken-less;
                }
            }
        }
    }


    // Accordion Menu <ul> (Mobile Only)
    .accordion-menu {
        opacity: 1.0;

        // Dropdown arrow color
        .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
            border-color: $white transparent transparent;
        }

        // Nested menu <ul>
        .is-accordion-submenu, 
        .nested.is-accordion-submenu {
            padding: 0;
            margin: 0;

            // Nested a
            li a {
                padding-left: 2rem;
            }

            // Nested a (third level)
            li li a {
                padding-left: 3rem;
            }

            // Nested a (fourth level)
            li li li a {
                padding-left: 4rem;
            }
        }
    }


    // Dropdown Menu <ul> (Desktop Only)
    .dropdown {
        opacity: 1.0; // covers js issue

        // "large" size +
        @include breakpoint(large) {
            justify-content: center;
            border-top: 0;
        }

        // Top Level <a>
        > li > a {

            // "large" size +
            @include breakpoint(large) {
                padding: 28px 1rem 0;
                height: $header-height-lg;
            }
        }

        // Adds drop shadow to active top level <a>
        > li.is-active > a {

            // "large" size +
            @include breakpoint(large) {
                -webkit-box-shadow: $shadow;
                -moz-box-shadow:    $shadow;
                box-shadow:         $shadow;
            }
        }
    }

    //  Top level <a> with dropdown
    .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {

        // "large" size +
        @include breakpoint(large) {
            padding-right: 1.75rem;
            position: relative;
        }

        // Dropdown arrow
        &:after {

            // "large" size +
            @include breakpoint(large) {
                right: 0.85rem;
            }
        }
    }
}



// Menu Toggle Div
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Toggle div that contains menu
.header__menu {
    display: none; // changed with js
    position: absolute;
    top: $header-height-sm;
    right: 0;
    width: 100%;
    max-width: 380px;
    padding: 1rem 1rem 0;

    // "medium" size +
    @include breakpoint(medium only) {
        top: $header-height-md;
    }

    // "large" size +
    @include breakpoint(large) {
        display: block; // always open
        top: 0 !important;
        width: 100%;
        max-width: 100%;
        padding-top: 0;
        padding-left: $full-space-lg;
        padding-right: $full-space-lg;
    }

    // Dropdown curtain
    &:after {

        // tablet and mobile only
        @include breakpoint(medium down) {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100vh;
            z-index: -1;
            background-color: $white;
            -webkit-box-shadow: $shadow;
            -moz-box-shadow:    $shadow;
            box-shadow:         $shadow;
        }
    }

    // When open
    &.show {
        display: block;
    }
}



// Menu Toggle Button
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Hamburger icon
.hamburger-icon {
	vertical-align: middle;
	display: inline-block;
	width: 25px;
	height: 3px;
	background-color: $secondary-color;
	margin: 0 auto;
	padding: 0;
	position: relative;

	// top and bottom bars
	&:before, &:after {
		content: "";
		position: absolute;
		left: 0;
		display: block;
		width: 25px;
		height: 3px;
		background-color: $secondary-color;
		margin: 0 auto;
		padding: 0;
	}

	// top bar
	&:before {
		top: -7px;
	}

	// bottom bar
	&:after {
		bottom: -7px;
	}
}

// X icon
.x-icon {
	vertical-align: middle;
	display: inline-block;
	width: 30px;
	height: 3px;
	position: relative;
	background-color: $secondary-color;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);

	&:after {
		content: "";
		display: block;
		width: 30px;
		height: 3px;
		background-color: $secondary-color;
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
	}
}

// Header menu toggle button
.header__menu-toggle-button {
    display: block;
    width: $header-height-sm;
    height: $header-height-sm;
    margin: 0;
    padding: 0;
    background: none;
    position: absolute;
    top: 0;
    right: 0;

    // "medium" size only
    @include breakpoint(medium only) {
        width: $header-height-md;
        height: $header-height-md;
    }

    // "large" size +
    @include breakpoint(large) {
        display: none;
    }

    // Hamburger icon
	.hamburger-icon {
        display: inline-block;
	}

	// Hamburger icon
	.x-icon {
		display: none;
    }
    
    // When open
    &[aria-expanded="true"] {

        // Hamburger icon
        .hamburger-icon {
            display: none;
        }

        // X icon
        .x-icon {
            display: inline-block;
        }
    }
}



// Header CTA Button
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Contains button
.header__cta-button {
    margin-top: 1rem;

    // "large" size +
    @include breakpoint(large) {
        position: absolute;
        top: 7px;
        right: $full-space-lg;
        margin-top: 0;
    }

    // Button
    .button {
        margin: 0;
        display: block;

        // "large" size +
        @include breakpoint(large) {
            display: inline-block;
        }
    }
}