// Base
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// html
html {
    background-color: $medium-gray;
    font-size: 16px;

    // "medium" size only
    @include breakpoint(medium only) {
        font-size: 17px;
    }

    // "large" size +
    @include breakpoint(large) {
        font-size: 18px;
    }
}

// body
body {
    font-size: 1rem;
    position: relative;

    // "large" size +
    @include breakpoint(large) {
        max-width: $body-max-width;
        margin: 0 auto;
    }
}



// Headings
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// All headings (h1-h6)
h1, .h1, 
h2, .h2, 
h3, .h3, 
h4, .h4, 
h5, .h5, 
h6, .h6 {
    display: block;
    width: 100%;
    font-weight: $bold;

    // 
    &.image {
        margin-right: 0;
    }
}

// h1 only
h1, .h1 {
    text-transform: none;
    letter-spacing: 0;
}

// h2 only
h2, .h2 {
    text-transform: none;
    letter-spacing: 0;
}

// h3 only
h3, .h3 {
    text-transform: none;
    letter-spacing: 0;
}

// h4 only
h4, .h4 {
    text-transform: none;
    letter-spacing: 0;
}

// h5 only
h5, .h5 {
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: inherit;
}

// h6 only
h6, .h6 {
    text-transform: none;
    letter-spacing: 0;
}



// Link Style
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// All anchors
a {
    font-weight: $bold;
    border-bottom: 2px solid $secondary-color;

    // Hover state
	&:hover, &:focus, &:active {
		transition: all 0.3s ease;
        background-color: $secondary-color;
	}
}

// Link/button hover transition
a:hover, button:hover {
	transition: all 0.3s ease;
}

// Class for link style
.link {
    display: inline;
    border-bottom: 2px solid $secondary-color;

    // Hover state
	&:hover, &:focus, &:active {
		transition: all 0.3s ease;
        background-color: $secondary-color;
	}
}

// Link style reset
%link-reset {
    border-bottom: 0;
    font-weight: 400;

    // Hover state
	&:hover, &:focus, &:active {
		background-color: transparent;
	}
}



// Misc. Typography
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Consistent element margins
blockquote, figure, figcaption, table, small, .well {
	margin: 0 0 1rem;
}

// Hides empty <p> tags (wysiwyg issue)
p:empty {
	display: none;
}

// Bold text
b, strong {
    font-weight: $bold;
}

// Italic text
i {
    font-style: italic;
}

// Emphasis text (bold italic)
em {
    font-style: italic;
    font-weight: $bold;
}

// Lead text
.lead {
    font-size: $lead-font-size;

    // "medium" size only
    @include breakpoint(medium only) {
        font-size: $lead-font-size;
    }

    // "large" size +
    @include breakpoint(large) {
        font-size: $lead-font-size;
    }
}

// Small text, fine print, captions
small, .fine, caption, figcaption {
    font-size: $small-font-size;
    font-style: normal;
    display: block;
}

// Address
address {
    display: block;
	margin: 0 0 1rem;
    line-height: inherit;
    font-style: normal;
}

// blockquote
blockquote {
    font-style: italic;
}

// Cite
cite {
    font-style: normal;
}

// Checkmark list
.checkmark-list {
    list-style: none;
    margin: 1rem 0 0;

    // li
    li {
        position: relative;
        padding-left: 1.75rem;
        margin: 0.5rem 0 0;

        // Adds checkmark
        &:before, &:after {
            position: absolute;
            content: "";
            background-color: $secondary-color;
        }

        // Adds checkmark
        &:before {
            top: 13px;
            left: 0;
            width: 3px;
            height: 9px;
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }

        // Adds checkmark
        &:after {
            top: 4px;
            left: 9px;
            width: 3px;
            height: 18px;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

// Unstyled list
.unstyled-list {
    padding: 0;
    margin: 0 0 1rem;
    list-style: none;

    // li
    li {
        display: block;
        padding: 0;
        margin: 0 0 0.5rem;
    }
}

// Unstyled list (store hours)
.unstyled-list--store-hours {

    li {
        margin-bottom: 0.75rem;
    }
    
    // Adds space after Day of Week
    b {
        display: inline-block;
        margin-right: 0.33rem;
    }
}

// "|" separators in footer bottom
.separator {
    display: inline-block;
    margin: 0 0.25rem;
}



// Horizontal Rules / Chips
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// horizontal rule
hr {
    width: $hr-width;
    height: 1px;
    margin: 1.5rem 0;
    background-color: $black-15;

    // "large" size +
    @include breakpoint(large) {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}