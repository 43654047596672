// Containers
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Narrow container
.narrow-container {
    display: block;
    margin: 0 auto; 
    width: 100%;
    max-width: 940px;
    padding: 0 2rem;

    // 480px - medium
    @media screen and (min-width: 480px) and (max-width:767px) {
        padding-left: $full-space-sm * 2;
        padding-right: $full-space-sm * 2;
    }

    // "medium" size only
    @include breakpoint(medium only) {
        padding-left: $full-space-md * 2;
        padding-right: $full-space-md * 2;
    }

    // "large" size +
    @include breakpoint(large) {
        padding-left: $full-space-lg * 2;
        padding-right: $full-space-lg * 2;
    }
}

// Grid container
.grid-container {
    max-width: 1680px;
    padding: 0 2rem;

    // 480px - medium
    @media screen and (min-width: 480px) and (max-width:767px) {
        padding-left: $full-space-sm;
        padding-right: $full-space-sm;
    }

    // "medium" size only
    @include breakpoint(medium only) {
        padding-left: $full-space-md;
        padding-right: $full-space-md;
    }

    // "large" size +
    @include breakpoint(large) {
        padding-left: $full-space-lg;
        padding-right: $full-space-lg;
    }
}

// Grid container with no max-width
.grid-container--no-max {
    max-width: 100%;
}



// <main>
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// <main>
main {

    // Remove focus outline
    &:focus {
        outline: none; // removes focus outline
    }
}



// Padded sections
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Padded section
.padded-section {
    position: relative;
    overflow: hidden;
    padding-top: $full-space-sm;
    padding-right: 0;
    padding-bottom: $full-space-sm;
    padding-left: 0;

    // "medium" size only
    @include breakpoint(medium only) {
        padding-top: $full-space-md;
        padding-bottom: $full-space-md;
    }

    // "large" size +
    @include breakpoint(large) {
        padding-top: $full-space-lg;
        padding-bottom: $full-space-lg;
    }
}