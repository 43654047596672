// Icomoon font path
$icomoon-path: '../fonts/icomoon/';

// Icomoon font face
@font-face {
  font-family: 'icomoon';
  src:    url('#{$icomoon-path}icomoon.eot?bs5lic');
  src:    url('#{$icomoon-path}icomoon.eot?bs5lic#iefix') format('embedded-opentype'),
          url('#{$icomoon-path}icomoon.ttf?bs5lic') format('truetype'),
          url('#{$icomoon-path}icomoon.woff?bs5lic') format('woff'),
          url('#{$icomoon-path}icomoon.svg?bs5lic#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

// All icons
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Custom styles
  margin-right: 0.5rem;
}

// Individual icons
.icon-phone:before {
  content: "\e942";
}
.icon-location:before {
  content: "\e947";
}
.icon-calendar:before {
  content: "\e953";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-arrow-up:before {
  content: "\ea3a";
}
.icon-arrow-right:before {
  content: "\ea3c";
}
.icon-arrow-down:before {
  content: "\ea3e";
}
.icon-arrow-left:before {
  content: "\ea40";
}
.icon-google:before {
  content: "\ea88";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-youtube:before {
  content: "\ea9d";
}
