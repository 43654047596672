// Images
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Contains images
.image {
    display: inline-block;
    width: 100%;
    margin: 0 0 1rem 0;

    // responsiveness
    img {
        width: 100%;
        max-width: 100%;
        height: auto;
        margin: 0;
        border: 0;
    }
}

// Linked image
a.image {
    @extend %link-reset;
}

// Large image size
.image--large {
    margin-right: 0.5rem;
    max-width: 250px;

    // "medium" size only
    @include breakpoint(medium only) {
        max-width: 375px;
    }

    // "large" size +
    @include breakpoint(large) {
        max-width: 500px;
    }
}

// Medium image size
.image--medium {
    margin-right: 0.5rem;
    max-width: 125px;

    // "medium" size only
    @include breakpoint(medium only) {
        max-width: 200px;
    }

    // "large" size +
    @include breakpoint(large) {
        max-width: 250px;
    }
}

// Small image size
.image--small {
    margin-right: 0.5rem;
    max-width: 45px;

    // "medium" size only
    @include breakpoint(medium only) {
        max-width: 60px;
    }

    // "large" size +
    @include breakpoint(large) {
        max-width: 90px;
    }
}

// Framed image
.image--framed {
    border: 1rem solid $white;
}

// Rounded image 
.image--rounded {
    border-radius: $global-radius;
    overflow: hidden;
}