// Buttons
// ---------------------------------------------
// ---------------------------------------------
// ---------------------------------------------

// All Buttons
.button {
    text-decoration: none;
    font-weight: $bold;


    // Default Button
    &.default {
        background-color: $black-075;
        color: $primary-color;

        // Hover state
	    &:hover, &:focus, &:active {
            background-color: $primary-color;
            color: $white;
        }
    }

    // Default Button (Hollow)
    &.default.hollow {
        position: relative;
        background-color: transparent;
        border-color: $black-15;

        // Hover state
	    &:hover, &:focus, &:active {
            background-color: $primary-color;
            border-color: $primary-color;
        }
    }


    // Primary Button
    &.primary {

        // Hover state
	    &:hover, &:focus, &:active {
            
        }
    }

    // Primary Button (Hollow)
    &.primary.hollow {

        // Hover state
	    &:hover, &:focus, &:active {
            background-color: $primary-darken;
            border-color: $primary-darken;
            color: $white;
        }

    }


    // Secondary Button
    &.secondary {

        // Hover state
	    &:hover, &:focus, &:active {
            
        }
    }

    // Secondary Button (Hollow)
    &.secondary.hollow {

        // Hover state
	    &:hover, &:focus, &:active {
            background-color: $secondary-darken;
            border-color: $secondary-darken;
            color: $white;
        }
        
    }
}