// A11y Helpers
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Screen Reader Only
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

// Screen Reader Only (Visible on Focus)
.sr-only-focusable {
    &:active, &:focus {
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        white-space: normal;
    }
}



// Typography Helpers
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Text uppercase
.text-uppercase {
    text-transform: uppercase;
}

// Text center
.text-center {

    // hr "chip"
    hr.chip--inline {
        margin-right: $chip-width / 4;
        margin-left: $chip-width / 4;
    }

    // all buttons
    .button {
        margin-right: 0.25rem;
        margin-left: 0.25rem;
    }
}

// Primary text color
.text-primary {
    color: $primary-color;
}

// Secondary text color
.text-secondary {
    color: $secondary-color;
}



// Text Reverse
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Reverses text color
.text-reverse {
    color: $white;

    // All headings (h1-h6)
    h1, .h1, 
    h2, .h2, 
    h3, .h3, 
    h4, .h4, 
    h5, .h5, 
    h6, .h6 {
        color: $white;
    }

    // hr
    hr {
        background-color: $white;
    }

    // Dashed list
    .chip-list li:before {
        background-color: $white-15;
    }

    // Callouts
    .callout {
        background-color: transparent;
        color: inherit;
        border-color: $white-15;
    }

    // Default Button
    .button.default {
        background-color: $white;
        color: $primary-color;

        // Hover state
        &:hover, &:focus, &:active {
            background-color: $light-gray;
        }
    }

    // Default Button (Hollow)
    .button.default.hollow {
        background-color: transparent;
        border-color: $white-15;
        color: $white;

        // Hover state
        &:hover, &:focus, &:active {
            background-color: $white;
            border-color: $white;
            color: $primary-color;
        }
    }

    // Border helper
    .bordered {
        border-color: rgba($white, 0.5);
    }
}



// Background Helpers
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Black bg
.black-bg {
    background-color: $black;
}

// Black 15 bg
.black-15-bg {
    background-color: $black-15;
}

// Black overlay bg
.black-overlay-bg {
    background-color: $black-overlay;
}

// Gray lightest bg
.gray-light-bg {
    // background-image: url('../img/gray-light-texture.jpg');
    // background-repeat: repeat;
    // background-position: center center;
    // background-size: 200px auto;
    background-color: $light-gray;
}

// White bg
.white-bg {
    background-color: $white;
}

// Primary bg
.primary-bg {
    background-color: $primary-color;
}

// Primary overlay bg
.primary-overlay-bg {
    background-color: $primary-overlay;
}

// Secondary bg
.secondary-bg {
    background-color: $secondary-color;
}

// Navy bg
.navy-bg {
    background-color: $blaines-navy;
}

// Cyan bg
.cyan-bg {
    background-color: $blaines-cyan;
}

// Red bg
.red-bg {
    background-color: $blaines-red;
}

// Primary color overlay
.overlay {

    // Adds overlay
    &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $primary-overlay;
    }

    // Fixes z-index issue
    > * {
        position: relative;
        z-index: 1;
    }
}

// Secondary color overlay
.overlay--secondary:after {
    background-color: $secondary-overlay;
}

// White color overlay
.overlay--white:after {
    background-color: $white-overlay;
}

// Needed for photo bg
.photo-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $dark-gray;
    background-position: center center;
}

// for fixed photo bg
.photo-bg--fixed {
    // "large" size +
    @include breakpoint(large) {
        background-attachment: fixed;
    }
}



// Margin / Spacing Helpers
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Adds margin-top
.mar-top {
    margin-top: 1.5rem;

    // "medium" size +
    @include breakpoint(medium) {
        margin-top: 2rem;
    }
}

// Adds $half-space margin top
.mar-top-half {
    margin-top: $half-space-sm;

    // "medium" size only
    @include breakpoint(medium only) {
        margin-top: $half-space-md;
    }

    // "large" size +
    @include breakpoint(large) {
        margin-top: $half-space-lg;
    }
}

// Adds $full-space margin top
.mar-top-full {
    margin-top: $full-space-sm;

    // "medium" size only
    @include breakpoint(medium only) {
        margin-top: $full-space-md;
    }

    // "large" size +
    @include breakpoint(large) {
        margin-top: $full-space-lg;
    }
}

// Margin top 0
.mar-top-0 {
    margin-top: 0;
}

// Adds margin-bottom
.mar-bottom {
    margin-bottom: 1.5rem;

    // "medium" size +
    @include breakpoint(medium) {
        margin-bottom: 2rem;
    }
}

// Adds $half-space margin bottom
.mar-bottom-half {
    margin-bottom: $half-space-sm;

    // "medium" size only
    @include breakpoint(medium only) {
        margin-bottom: $half-space-md;
    }

    // "large" size +
    @include breakpoint(large) {
        margin-bottom: $half-space-lg;
    }
}

// Adds $full-space margin bottom
.mar-bottom-full {
    margin-bottom: $full-space-sm;

    // "medium" size only
    @include breakpoint(medium only) {
        margin-bottom: $full-space-md;
    }

    // "large" size +
    @include breakpoint(large) {
        margin-bottom: $full-space-lg;
    }
}

// Margin bottom 0
.mar-bottom-0 {
    margin-bottom: 0;
}

// Spacer
.spacer {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    background: transparent;
    height: 2rem;

    // 480px - medium
    @media screen and (min-width: 480px) and (max-width:767px) {
        height: $full-space-sm;
    }

    // "medium" size only
    @include breakpoint(medium only) {
        height: $full-space-md;
    }

    // "large" size +
    @include breakpoint(large) {
        height: $full-space-lg;
    }
}

// Spacer (Half)
.spacer--half {
    height: 1rem;

    // 480px - medium
    @media screen and (min-width: 480px) and (max-width:767px) {
        height: $half-space-sm;
    }

    // "medium" size only
    @include breakpoint(medium only) {
        height: $half-space-md;
    }

    // "large" size +
    @include breakpoint(large) {
        height: $half-space-lg;
    }
}

// Spacer (Nav)
.spacer--nav {
    height: $header-height-sm;

    // "medium" size only
    @include breakpoint(medium only) {
        height: $header-height-md;
    }

    // "large" size +
    @include breakpoint(large) {
        height: $header-height-lg;
    }
}



// Misc. Style Helpers
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Opacity 75%
.opacity-75 {
    opacity: 0.75;
}

// Opacity 50%
.opacity-50 {
    opacity: 0.5;
}

// Opacity 20%
.opacity-20 {
    opacity: 0.2;
}

// Adds shadow
.shadow {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow:    $shadow;
    box-shadow:         $shadow;
}

// Border helper
.bordered {
    border: 1px solid $black-15;
}

// Removes border
.no-border {
    border: 0;
}