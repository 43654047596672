// Home Hero Section
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Home Hero Section
.home-section {
    position: relative;
    margin-top: $header-height-sm;

    // medium
    @include breakpoint(medium) {
        margin-top: $header-height-md;
    }

    // large
    @include breakpoint(large) {
        margin-top: 0;
    }

    // Gradient Overlay
    &:after {

        // large
        @include breakpoint(large) {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50%;
            background: linear-gradient(rgba(0, 0, 0, 0.0), $black-overlay);
            z-index: 1;
        }
    }
}

// Home Hero Section Content
.home-section--content {
    padding: 2rem;
    text-align: center;
    background: $primary-color;

    // 480px - medium
    @media screen and (min-width: 480px) and (max-width:767px) {
        padding-left: $full-space-sm;
        padding-right: $full-space-sm;
    }

    // medium
    @include breakpoint(medium) {
        padding: $full-space-md;
    }

    // large
    @include breakpoint(large) {
        padding: $full-space-lg;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        z-index: 2;
        background-color: transparent;
    }
}



// Cards
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Card
.card {
    margin-bottom: 1rem;
    border-radius: $global-radius;
    overflow: hidden;
}

// Card Section
.card-section {
    padding: 1.5rem;
    @include breakpoint(medium) {
        padding: 2rem;
    }
}



// Featured (50/50) Image Block
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// 50/50 content
.content-50-50 {
    width: 100%;
    max-width: 100%;

    // Needed for bug fix
    > div {
        width: 100%;
        max-width: 100%;
    }
}

// Content on the left (on desktop)
.content-50-50--left {

    // "medium" size only
    @include breakpoint(medium only) {
        padding-right: 0.5rem;
    }

    // "large" size +
    @include breakpoint(large) {
        padding-right: 1rem;
    }
}

// Content on the right (on desktop)
.content-50-50--right {

    // "medium" size only
    @include breakpoint(medium only) {
        padding-left: 0.5rem;
    }

    // "large" size +
    @include breakpoint(large) {
        padding-left: 1rem;
    }
}

// Vertically centers 50/50 content
.content-50-50--vertical-center {

	// "medium" size +
    @include breakpoint(medium) {
		display: flex;
		align-items: center;
		justify-content: center;
    }
}



// Timeline Listing
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Timeline list
.timeline {
    margin: 0;
    padding: $half-space-sm 0 0;
    list-style: none;
    border-left: 1px solid $black-15;

    // medium +
    @include breakpoint(medium) {
        padding-top: $half-space-md;
    }

    // large +
    @include breakpoint(large) {
        padding-top: $half-space-lg;
    }
}

// Timeline item
.timeline__item {
	position: relative;
    margin: 0;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
    list-style: none;

    // medium +
    @include breakpoint(medium) {
        padding-bottom: 2rem;
        padding-left: 2rem;
    }

	// Bullet
	&:before {
		content: "";
		display: block;
		position: absolute;
		top: auto;
		left: -7px;
		width: 14px;
		height: 14px;
        background-color: $secondary-color;
        border: 3px solid $white-15;
        border-radius: 50%;

        // "medium" size +
        @include breakpoint(medium) {
            left: -10px;
			width: 20px;
			height: 20px;
        }
	}

	// No margin-bottom on last child
	> *:last-child {
		margin-bottom: 0;
	}
}

// Timeline time
.timeline__item__time {
    //font-size: 1rem;
    //text-transform: uppercase;
}



// Google map embed
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Contains iframe
.map-embed {
    display: block;
    width: 100%;
    height: 350px;
    min-height: 350px;
    overflow: visible;
    background-color: $medium-gray;

    // "medium" size +
    @include breakpoint(medium) {
        height: 100%;
    }

    // iframe
    iframe {
        border: 0;
        width: 100%;
        height: 100%;
    }
}